<template>
  <div>
    <modal :show="modalCompany">
      <form class="pb-4 pl-2 pr-2 pt-4">
        <label>Nome da unidade</label>
        <base-input type="text" v-model="company.name" placeholder="Nome" required />
        <label>Cnpj</label>
        <base-input type="numeric" v-model="company.cnpj" placeholder="CNPJ" v-mask="'##############'" required />

        <div class="custom-switch">
          <input type="checkbox" v-model="company.enabled" class="custom-control-input" id="antigas" />
          <label class="custom-control-label form-control-label" for="antigas">Ativar</label>
        </div>
      </form>

      <div class="mt-4">
        <button class="btn btn-primary" @click="save(company)">Salvar</button>
        <button class="btn btn-outline" @click="modalCompany = false">Cancelar</button>
      </div>
    </modal>

    <base-header class="pb-1">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0"></h6>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--4">
      <form>
        <div class="col-md-12 ">
          <card class="pl-5 pr-5">
            <h3 slot="header" class="mb-0">Unidades</h3>
            <div class="col-md-12 text-right mb-3 pr-5">
              <base-button type="neutral" icon size="sm" @click="open(defaultCompany)">
                <span class="btn-inner--icon"><i class="fas fa-building"></i></span>
                <span class="btn-inner--text">Nova Unidade</span>
              </base-button>
            </div>
            <table class="table">
              <thead class="thead">
                <tr>
                  <th scope="col" class="text-center">Id da unidade</th>
                  <th scope="col" class="text-center">Nome</th>
                  <th scope="col" class="text-center">CNPJ</th>
                  <th scope="col" class="text-center">habilitada?</th>
                  <th scope="col" class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="company in companies" :key="company.id">
                  <td class="text-center">{{ company.id }}</td>
                  <td class="text-center">{{ company.name }}</td>
                  <td class="text-center">{{ company.cnpj }}</td>
                  <td class="text-center">{{ company.enabled ? "Sim" : "Não" }}</td>
                  <td> <a href="#" title="Alterar plano" @click.prevent="open(company)"><i class="fas fa-cog"></i></a>
                  </td>
                </tr>
                <tr v-show="companies.length == 0">
                  <td colspan="8">
                    <center>Nenhuma unidade disponível.</center>
                  </td>
                </tr>
              </tbody>
            </table>
          </card>
        </div>

      </form>
    </div> <!--- fecha conteudo -->

  </div>
</template>
<script>

const defaultCompany = {
  name: '',
  cnpj: '',
  enabled: false
}

export default {
  mounted() {
    this.loadCompanies()
  },
  data() {
    return {
      defaultCompany,
      modalCompany: false,
      company: defaultCompany,
      companies: []
    };
  },
  methods: {
    validate(dto) {
      if (dto.name == "" || dto.cnpj == "") {
        this.$notify({ type: 'warning', message: "Campo(s) em branco." })
        return false
      }
      if (dto.cnpj.length != 14) {
        this.$notify({ type: 'warning', message: "CNPJ invalido." })
        return false
      }
      return true
    },
    save(company) {
      if (this.validate(company)) {
        this.process(company)
          .then((message) => {
            this.loadCompanies();
            this.modalCompany = false;
            this.$notify({ type: 'success', message: message })
          })
          .catch((err) => this.$notify({ type: 'warning', message: err }))
      }
    },
    process(dto) {
      return new Promise((resolve, reject) => {
        if (dto.id) {
          this.$clubApi.put("/company", dto)
            .then(() => resolve("Unidade Atualizada."))
            .catch((err) => reject(err))
        } else {
          this.$clubApi.post("/company", dto)
            .then(() => resolve("Unidade criada."))
            .catch((err) => reject(err))
        }
      })
    },
    open(company) {
      const { _, ...data } = company

      this.company = data
      this.modalCompany = true
    },
    loadCompanies() {
      this.$clubApi.get("/company")
        .then(res => this.companies = res.data.object)
    }
  }
};
</script>
<style></style>
